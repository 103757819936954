import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import LogoInicio from "../componentes/LogoInicio";
import { obras } from "../obras";

const Acuarelas = () => {
  const [zoomImagen, setZoomImagen] = useState({ estado: false, imagen: "" });
  return (
    <>
      <Row className="pagina-inicio">
        <Col md={12} className="logo-inicio">
          <Row className="nombre"><a href="/acuarelas">NereaGarcíaCasas</a></Row>
        </Col>
        <Col className={`${zoomImagen.estado ? "oscuro" : "d-none"}`}
          onClick={() => setZoomImagen({ estado: false, imagen: "" })}></Col>
        <Col className="obras">
          {
            obras
              .filter(obra => obra.acuarelas)
              .map(obra => obra.acuarelas
                //.sort((a, b) => b.año - a.año)
                .map((obra, i) =>
                  <Row className="obra" key={obra.nombre}
                    onClick={() => setZoomImagen({ estado: true, imagen: obra.nombre })}>
                    <Col className={`imagen-obra ${zoomImagen.estado && "translucido"}`}>
                      <img src={`./img/acuarelas/${obra.nombre}.JPG`} alt={`Acuarela#${i + 1}`} />
                    </Col>
                    <Col
                      className={"info-obra detalle"}>
                      <Row>{`Acuarela#${i + 1}`}</Row>
                      <Row>{obra.medidas}</Row>
                      {/* <Row>{obra.precio}</Row> */}
                    </Col>
                  </Row>)
              )
          }
          <Row className={`zoom imagen-obra ${zoomImagen.estado ? "aumentar" : "oculto"}`}>
            <img src="./img/iconos/cruz.png" alt="Cerrar Imagen"
              onClick={() => setZoomImagen({ estado: false, imagen: "" })} />
            <img src={`./img/acuarelas/${zoomImagen.imagen}.JPG`} alt={"Acuarela"} />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Acuarelas;
