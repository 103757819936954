import { Col, Row } from "react-bootstrap";
import LogoInicio from "../componentes/LogoInicio";

const Inicio = () => {
  return (
    <>
      <Row className="pagina-inicio">
        <LogoInicio />
        <Col md={12} className="barra-enlaces">
          <Row>
            <a href="/desarrollo-web">
              <Col className="video-inicio">
                <video src="./vid/web.mp4" type="video/mp4" loop autoPlay />
                  <Row className="enlace">Desarrollo Web</Row>
                </Col>
            </a>
          </Row>
          <Row>
            <a href="/logo">
              <Col className="video-inicio">
                <video src="./vid/logo.mp4" type="video/mp4" autoPlay loop muted />
                <Row className="enlace">Logo</Row>
              </Col>
            </a>
          </Row>
{/*           <Row>
            <a href="/ilustracion">
              <Col className="video-inicio">
                <video src="./vid/ilustracion.mp4" type="video/mp4" autoPlay loop muted />
                <Row className="enlace">Ilustración</Row>
              </Col>
            </a>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};

export default Inicio;
