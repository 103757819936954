import { Col, Row } from "react-bootstrap";

import { MdOutlineEmail } from "react-icons/md";
import { AiOutlineLinkedin } from "react-icons/ai";

const LogoInicio = () => {
  return (
    <Col className="logo-inicio">
      <Row className="nombre"><a href="/inicio">NereaGarcíaCasas</a></Row>
      <Row className="iconos">
        <a href="mailto:nereagarcia.c@gmail.com?subject=Contacto Web">
          <Col className="email">
            {/* <img src="/img/iconos/email-neon2.png" alt="Contáctame por Email" />
            <img src="/img/iconos/email.png" alt="Contáctame por Email" /> */}
            <MdOutlineEmail />
          </Col>
        </a>
{/*         <a href="https://www.instagram.com/enegece/" target="_blank" rel="noreferrer">
          <Col className="insta">
            <img src="/img/iconos/insta-neon.png" alt="Contáctame por Instagram" />
            <img src="/img/iconos/insta.png" alt="Contáctame por Instagram" />
          </Col>
        </a> */}
        <a href="https://www.linkedin.com/in/nerea-garcia-casas/" target="_blank" rel="noreferrer">
          <Col className="linkedin">
            <AiOutlineLinkedin />
          </Col>
        </a>
      </Row>
    </Col>
  );
};

export default LogoInicio;
