import { Col, Row } from "react-bootstrap";
import LogoInicio from "../componentes/LogoInicio";
import { obras } from "../obras";
import { useState } from "react";

const Logo = () => {
  const [zoomImagen, setZoomImagen] = useState({ estado: false, imagen: "" });
  return (
    <Row className="pagina-inicio">
      <Col className={`${zoomImagen.estado ? "oscuro" : "d-none"}`}
        onClick={() => setZoomImagen({ estado: false, imagen: "" })}></Col>
      <LogoInicio />
      <Col md={12} className="cambio-enlace">
        <Row className="enlace-no-elegido">
          <a href="/desarrollo-web">Desarrollo Web</a>
        </Row>
        <Row className="enlace-elegido">Logo</Row>
{/*         <Row className="enlace-no-elegido">
          <a href="/ilustracion">Ilustración</a>
        </Row> */}
      </Col>
      <Col className="obras">
        {
          obras
            .filter(obra => obra["logo"])
            .map(obra => obra["logo"]
              .map(obra =>
                <Row className="obra my-2" key={obra.nombre}
                  onClick={() => setZoomImagen({ estado: true, imagen: obra.nombre })}>
                  <Col className={`imagen-obra ${zoomImagen.estado && "translucido"}`}>
                    <img src={`./img/logo/${obra.nombre}.jpg`} alt={`${obra.nombre}`} />
                  </Col>
                  <Col
                    className={"info-obra detalle"}
                  >
                    <Row>{obra.nombre}</Row>
                    <Row>{obra.herramientas}</Row>
                    <Row>{obra.año}</Row>
                  </Col>
                </Row>
              ))
        }
        <Row className={`zoom imagen-obra ${zoomImagen.estado ? "aumentar" : "oculto"}`}>
          <img src="./img/iconos/cruz.png" alt="Cerrar Imagen"
            onClick={() => setZoomImagen({ estado: false, imagen: "" })} />
          <img src={`./img/logo/${zoomImagen.imagen}.jpg`} alt={`${zoomImagen.imagen}`} />
        </Row>
      </Col>
    </Row>
  );
};

export default Logo;
