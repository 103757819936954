import {
  Route, BrowserRouter as Router, Switch, Redirect
} from "react-router-dom";
import { Container } from "react-bootstrap";

import Inicio from "./paginas/Inicio";
import DesarrolloWeb from "./paginas/DesarrolloWeb";
import Ilustracion from "./paginas/Ilustracion";
import Acuarelas from "./paginas/Acuarelas";
import Logo from "./paginas/Logo";

function App() {
  return (
    <Container fluid>
      <Router>
        <Switch>
          <Route path="/inicio" exact>
            <Inicio />
          </Route>
          <Route path="/desarrollo-web" exact>
            <DesarrolloWeb />
          </Route>
{/*           <Route path="/ilustracion" exact>
            <Ilustracion />
          </Route> */}
          <Route path="/acuarelas" exact>
            <Acuarelas />
          </Route>
          <Route path="/logo" exact>
            <Logo />
          </Route>
          <Redirect to="inicio"/>
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
