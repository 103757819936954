export const obras = [
    {
      "desarrollo-web": [
        {
          "nombre": "Logopeda - Terapeuta Infantil",
          "año": 2023,
          "herramientas": "WordPress, CSS, Diseño Logo",
          "enlace": "https://logopedapaloma.com/"
        },
        {
          "nombre": "Unde",
          "año": 2022,
          "herramientas": "JavaScript (React), Sass, Diseño Logo y Maqueta Web",
          "enlace": "https://unde.vercel.app/"
        },
        {
          "nombre": "Div(So)",
          "año": 2022,
          "herramientas": "JavaScript (React), Diseño Logo y Maqueta Web",
          "enlace": "https://64f46b13e822fe064278c79a--div-so.netlify.app/inicio"
        },
        {
          "nombre": "Psicología Integral",
          "año": 2021,
          "herramientas": "WordPress, CSS, Diseño Logo"
        },
        {
          "nombre": "Punto Ciudadano (Proyecto grupal Bootcamp)",
          "año": 2021,
          "herramientas": "JavaScript (React y Node), Bootstrap4, CSS, MongoDB",
          "enlace": "https://puntociudadano.netlify.app/inicio"
        }
      ]
    },
    {
      "logo": [
        {
          "nombre": "Logopeda - Terapeuta Infantil",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Psicología Integral",
          "año": 2021,
          "herramientas": "Illustrator"
        },
        {
          "nombre": "Unde",
          "año": 2021,
          "herramientas": "Illustrator"
        },
        {
          "nombre": "Div(So)",
          "año": 2020,
          "herramientas": "Illustrator"
        }
      ]
    },
    {
      "ilustracion": [
        {
          "nombre": "Alicia",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Buscando",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Un día cualquiera",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "En el bus",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Caricatura",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Maki",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Nigiri",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Planta",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Ramen",
          "año": 2021,
          "herramientas": "Illustrator"
        },
        {
          "nombre": "Retrato Kimiko (The Boys)",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Retrato Naomi (The Expanse)",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Submarino",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Flores",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Nadador",
          "año": 2021,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Auto-retrato",
          "año": 2020,
          "herramientas": "Photoshop"
        },
        {
          "nombre": "Buscando el tesoro perdido",
          "año": 2023,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Gestos",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Kintsugi",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Recuerdos de un viaje",
          "año": 2022,
          "herramientas": "Procreate"
        },
        {
          "nombre": "Terrario",
          "año": 2022,
          "herramientas": "Procreate"
        }
      ]
    },
    {
      "acuarelas": [
        {
          "nombre": "ajos",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "barco",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "barcos",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "bosque",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "callejuela",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "campo",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "campo2",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "casa-bosque",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "cerrojo",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "gato",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "halcon",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "jarron1",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "jarron2",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "niebla",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "noche-puerto",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "palmera",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "perro",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "rocas",
          "medidas": "23x32,5 cm"
        },
        {
          "nombre": "ventana",
          "medidas": "23x32,5 cm"
        }
      ]
    }
  ];
