import { Col, Row } from "react-bootstrap";
import LogoInicio from "../componentes/LogoInicio";
import { obras } from "../obras";

const DesarrolloWeb = () => {
  return (
    <Row className="pagina-inicio">
      <LogoInicio />
      <Col md={12} className="cambio-enlace">
        <Row className="enlace-elegido">Desarrollo web</Row>
        <Row className="enlace-no-elegido">
          <a href="/logo">Logo</a>
        </Row>
{/*         <Row className="enlace-no-elegido">
          <a href="/ilustracion">Ilustración</a>
        </Row> */}
      </Col>
      <Col className="obras">
        {
          obras
            .filter(obra => obra["desarrollo-web"])
            .map(obra => obra["desarrollo-web"]
              .map(obra =>
                <Row className="obra my-2" key={obra.nombre}>
                  <Col className="imagen-obra web">
                    <img src={`./img/web/${obra.nombre}.jpg`} alt={`${obra.nombre}`} />
                  </Col>
                  <Col
                    className={"info-obra web detalle"}
                  >
                    <Row>{obra.nombre}</Row>
                    <Row>{obra.herramientas}</Row>
                    <Row>{obra.año}</Row>
                    {
                      obra.enlace
                        ? (<Row className="link-externo">
                          <a href={`${obra.enlace}`} target="_blank" rel="noreferrer">
                            Visitar
                            <i className="fas fa-external-link-alt"></i>
                          </a></Row>)
                        : <Row  style={{ color: "orange" }}>Dada de baja</Row>
                    }
                  </Col>
                </Row>
              ))
        }
      </Col>
    </Row>
  );
};

export default DesarrolloWeb;
